import { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth'
import { toast } from 'react-toastify'

const Login = () => {
  const [formData, setFormData] = useState({
    email: '',
    password: '',
  })
  const { email, password } = formData

  const navigate = useNavigate()

  const onSubmit = async (e) => {
    e.preventDefault()

    try {
      const auth = getAuth()
      const userCredential = await signInWithEmailAndPassword(auth, email, password)

      const user = userCredential.user
      console.log(user)
    } catch (error) {
      toast.error('Bad User Credentials', {
        autoClose: '3000',
        position: 'top-center',
      })
    }
  }

  const onMutate = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.id]: e.target.value,
    }))
  }

  return (
    <>
      <div className='w-full h-screen flex flex-col justify-center items-center'>
        <h1 className='mb-8 text-center font-bold text-4xl mx-8'>
          PER ACCEDERE ALLA DASHBOARD FARE IL LOGIN
        </h1>
        <form onSubmit={onSubmit} className='w-full max-w-[400px] mx-auto'>
          <div className='mb-3 mt-3'>
            <input
              type='text'
              className='input input-bordered w-full text-l lg:text-xl'
              id='email'
              name='email'
              value={email}
              placeholder='email'
              onChange={onMutate}
              required
            />
          </div>
          <div className='mb-3 mt-3'>
            <input
              type='password'
              className='input input-bordered w-full text-l lg:text-xl'
              id='password'
              name='password'
              value={password}
              placeholder='password'
              onChange={onMutate}
              required
            />
          </div>
          <button
            type='submit'
            className='btn w-full mt-3 mx-auto hover:bg-white hover:text-black'
          >
            Login
          </button>
        </form>
      </div>
    </>
  )
}

export default Login
