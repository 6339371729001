import React from 'react'

const AziendaItem = ({ title, text, text1, text2, text3, position, img }) => {
  return (
    <>
      <div
        className={
          position === 'right'
            ? 'flex flex-col lg:flex-row-reverse justify-around items-center mb-12 w-full'
            : 'flex flex-col lg:flex-row justify-around items-center mb-12 w-full'
        }
      >
        <img
          src={img}
          alt='Azienda'
          className='w-full lg:w-3/5 h-auto mb-5 rounded-lg shadow-4xl animate__animated animate__fadeInLeft animate__delay-1s'
        />
        <h2
          className='writing-mode text-4xl lg:text-5xl font-semibold text-center justify-self-center animate__animated animate__fadeInDown animate__delay-1s p-3 transform rotate-90'
        >
          {title}
        </h2>

        {text1 ? (
          <div className='w-full lg:w-1/3'>
            <p className='text-justify text-xl animate__animated animate__zoomIn  animate__delay-1s mb-3'>
              {text1}
            </p>
            <p className='text-justify text-xl animate__animated animate__zoomIn  animate__delay-1s mb-3'>
              {text2}
            </p>
            <p className='text-justify text-xl animate__animated animate__zoomIn  animate__delay-1s'>
              {text3}
            </p>
          </div>
        ) : (
          <p className='w-full lg:w-1/3 text-justify text-xl animate__animated animate__zoomIn  animate__delay-1s'>
            {text}
          </p>
        )}
      </div>
    </>
  )
}

export default AziendaItem
