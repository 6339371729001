import i18next from "i18next"
import { useEffect } from "react"

const SelectLang = () => {
  useEffect(() => {
    
  }, [])

  const languages = [
    {
      code: 'it',
      name: 'IT',
      country_code: 'it',
      icon: '🇮🇹'
    },
    {
      code: 'en',
      name: 'EN',
      country_code: 'gb',
      icon: '🇬🇧'
    },
  ]

  return (
    <select
      className='select select-info text-white bg-black opacity-50 w-20 lg:w-28 rounded-xl top-2 border-black lg:top-5 right-8 lg:right-14 z-50 fixed'
      onChange={(e) => i18next.changeLanguage(e.target.value)}
    >
      {languages.map(({ code, name, icon }, index) => (
        <option key={index} value={code} className='text-center'>
          {icon}{' '}{name}
        </option>
      ))}
    </select>
  )
}

{
}

export default SelectLang
