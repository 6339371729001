import Form from "./Form"
import { useContext } from 'react'
import BrmContext from '../../../context/BrmContext'

const FormContainer = () => {
  const { t } = useContext(BrmContext)


   return (
     <>
       <div className='animate__animated animate__fadeInDown animate-delay-1s collapse collapse-arrow w-4/5 sm:w-3/4 mx-auto bg-white rounded-box border border-base-300 shadow-5xl'>
         <input type='checkbox' className='peer' />
         <div className='collapse-title text-m lg:text-xl font-bold text-center'>
           {t('form_container1')}
           <br /> {t('form_container2')} <span></span>
         </div>
         <div className='collapse-content text-xl text-center mx-auto mb-3 w-3/4'>
           <Form />
         </div>
       </div>
     </>
   )
}

export default FormContainer
