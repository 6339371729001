import { getAuth, onAuthStateChanged } from 'firebase/auth'
import { useEffect } from 'react'
import { useState } from 'react'

//what is solved through this hook is that even if I am logged in and I reload and I am in the profile section, I will get an error because the component is rendered before the data is obtained from firebase


const useAuthStatus = () => {
  const [loggedIn, setLoggedIn] = useState(false)

  const auth = getAuth()

  useEffect(() => {
   onAuthStateChanged(auth, (user) => {
      if(user) {
        setLoggedIn(true)
      }
      console.log(user)
    })
  }, [auth])

  return loggedIn
}

export default useAuthStatus