import TitlePage from '../components/layout/TitlePage'
import ContactItem from '../components/layout/contacts/ContactItem'
import Map from '../components/layout/contacts/Map'
import message from '../components/assets/icon/message.svg'
import telegram from '../components/assets/icon/telegram2.png'
import mail from '../components/assets/icon/mail.svg'
import location from '../components/assets/icon/location.svg'
import FormContainer from '../components/layout/contacts/FormContainer'
import { useContext, useEffect, useState } from 'react'
import BrmContext from '../context/BrmContext'
import { Helmet } from 'react-helmet-async'
import Loader from '../components/Loader'

function Contatti() {
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    window.scrollTo(0, 0)
    setInterval(() => {
      setIsLoading(false)
    }, 1500)
  }, [])
  const { t } = useContext(BrmContext)

  const contatti = [
    {
      name: 'Telegram',
      link: 'https://t.me/brmconfezioni',
      contact: '@brmconfezioni',
      img: telegram,
      animation: 'animate__animated animate__pulse',
    },
    {
      name: 'Whatsapp',
      link: 'https://api.whatsapp.com/send?phone=390376550373',
      contact: '(+39) 0376 550373',
      img: message,
      animation: 'animate__animated animate__pulse',
    },
    {
      name: 'Email',
      link: 'mailto:commerciale@brmconfezioni.it',
      contact: 'commerciale@brmconfezioni.it',
      img: mail,
      animation: 'animate__animated animate__pulse',
    },
    {
      name: t('contatti_posizione'),
      link: 'https://www.google.com/maps/dir//Via+Sandro+Pertini,+2+46020+Tabino+Mottelle+MN/@44.9908894,10.8441455,16z/data=!4m8!4m7!1m0!1m5!1m1!1s0x477f872205655187:0xd87eacbd10c9bb46!2m2!1d10.8441455!2d44.9908894',
      contact: ['Via Sandro Pertini 2/A', '- Pegognaga (MN) 46020'],
      img: location,
      animation: 'animate__animated animate__pulse',
    },
  ]

  return isLoading ? (
    <Loader />
  ) : (
    <>
      <Helmet>
        <title>
          BRM Confezioni Produttori di Intimo e Costumi da bagno | Contatti
        </title>
        <meta
          name='description'
          content='Contatti disponibili per qualsiasi confronto. '
        />
        <link rel='canonical' href='/contatti' />
      </Helmet>

      <TitlePage title={t('sidebar_contatti')} />
      <FormContainer />
      {/* quando inserisci form inserisci questa classe in section: min-h-[80vh] */}
      <section className='w-full p-5'>
        <div className='lg:w-4/5 w-full p-3 mx-auto flex flex-wrap justify-around md:justify-around'>
          {contatti.map((contatto, index) => (
            <ContactItem
              key={index}
              name={contatto.name}
              link={contatto.link}
              contact={contatto.contact}
              img={contatto.img}
              animation={contatto.animation}
            />
          ))}
        </div>
      </section>
      <Map />
    </>
  )
}

export default Contatti
