import { getAuth, GoogleAuthProvider, signInWithPopup } from 'firebase/auth'
import { initializeApp } from 'firebase/app'
import { getStorage } from 'firebase/storage'
import { getAnalytics } from 'firebase/analytics'
import { getFirestore } from 'firebase/firestore'



// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: 'AIzaSyB8-7qiR610OarQw70L4DHyKLEkO8TF0nc',
  authDomain: 'brm-confezioni.firebaseapp.com',
  projectId: 'brm-confezioni',
  storageBucket: 'brm-confezioni.appspot.com',
  messagingSenderId: '141592658291',
  appId: '1:141592658291:web:67bf95158a1b51c3930506',
  measurementId: 'G-2FH3LQ0SYZ',
}

// Initialize Firebase
const app = initializeApp(firebaseConfig)

export const auth = getAuth()
const provider = new GoogleAuthProvider()
export const signInWithGoogle = () =>
  signInWithPopup(auth, provider)

const analytics = getAnalytics(app)
export const db = getFirestore(app)
export const storage = getStorage(app)
