import { useEffect, useState } from 'react'

import './chart.css'
import BarChart from './BarChart'

const Chart = () => {
  const [isLinkElementLoaded, setLinkElementLoaded] = useState(false)

  useEffect(() => {

    // aggiungo il link tag di fontawesome per poter utilizzare gli unicode.
    
    const linkElement = document.createElement('link')
    linkElement.setAttribute('rel', 'stylesheet')
    linkElement.setAttribute('type', 'text/css')
    linkElement.setAttribute(
      'href',
      'https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.css'
    )
    document.head.appendChild(linkElement)
    
    setLinkElementLoaded(true)

  }, [])
  const colours = [
    'rgb(255,26,104)',
    'rgba(255, 159, 64, 1)',
    'rgb(75, 192, 192)',
  ]

  const [chartData, setChartData] = useState({
    // labels: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
    datasets: [
      {
        label: 'Weekly Sales',
        data: [
          {
            x: ['2023-01-03', '2023-01-06'],
            y: 'Task 1',
            name: 'James',
            status: 2, //completed
          },
          {
            x: ['2023-01-06', '2023-01-12'],
            y: 'Task 2',
            name: 'Luna',
            status: 2,
          },
          {
            x: ['2023-01-09', '2023-01-12'],
            y: 'Task 3',
            name: 'Carlos',
            status: 2,
          },
          {
            x: ['2023-01-15', '2023-01-21'],
            y: 'Task 4',
            name: 'Genny',
            status: 2,
          },
          {
            x: ['2023-01-12', '2023-01-25'],
            y: 'Task 5',
            name: 'Pollo',
            status: 1,
          },
          {
            x: ['2023-01-18', '2023-01-30'],
            y: 'Task 6',
            name: 'Alex',
            status: 0,
          },
        ],
        backgroundColor:(ctx) => {
          //attraverso questa funzione riusciamo ad accedere allo status degli elementi presenti nel grafico, come se fosse un forEach, ed assegnar loro i corrispondenti colori in base allo status 
          return colours[ctx.raw.status]
        },
        borderSkipped: false,
        borderRadius: 10,
        barPercentage: 0.5, //50% più piccola
      },
    ],
  })

  return (
    <>
      {isLinkElementLoaded && (
        <div className='w-full h-screen flex items-center'>
          <BarChart chartData={chartData} colours={colours} />
        </div>
      )}
    </>
  )
}

export default Chart
