

function Footer() {
  return (
    <footer className="footer footer-center p-4 bg-white text-base-content">
      <div>
        <p>Copyright © 2022 - All right reserved by B.R.M. di Iotti Gianluca P.IVA 02606210207</p>
      </div>
    </footer>
  );
}

export default Footer
