import instaLogo from '../../assets/icon/instagram.png'
import whatsappLogo from '../../assets/icon/whatsapp.png'
import telegramLogo from '../../assets/icon/telegram.png'
import qrInsta from '../../assets/qrcode/qrinstagram.png'
import qrWhatsapp from '../../assets/qrcode/qrwhatsapp.png'
import qrTelegram from '../../assets/qrcode/qrTelegram.png'
import { ExternalLink } from 'react-external-link'

function Social() {
  return (
    <ul className='nav-link social-link-list flex justify-around items-center w-48 mb-20 relative'>
      <li className='social-elem'>
        <div className='social-qr absolute left-2/4 w-20 md:w-28 bottom-11 md:bottom-14 -translate-x-2/4  hidden'>
          <img src={qrTelegram} alt='qrTelegram' />
        </div>
        <ExternalLink href='https://t.me/brmconfezioni'>
          <img
            src={telegramLogo}
            alt='telegram'
            width={40}
            className='w-8'
          />
        </ExternalLink>
      </li>
      {/* <li className='social-elem'>
        <div className='social-qr absolute left-2/4 w-20 md:w-28 bottom-11 md:bottom-14 -translate-x-2/4  hidden'>
          <img src={qrInsta} alt='qrInsta' />
        </div>
        <ExternalLink href='https://www.instagram.com/brmconfezioni/?hl=it'>
          <img
            src={instaLogo}
            alt='instagram'
            width={40}
            className='w-8'
          />
        </ExternalLink>
      </li> */}
      <li className='social-elem'>
        <div className='social-qr absolute left-2/4 w-20 md:w-28 bottom-11 md:bottom-14 -translate-x-2/4 hidden'>
          <img src={qrWhatsapp} alt='qrInsta' />
        </div>
        <ExternalLink href='https://api.whatsapp.com/send?phone=390376550373'>
          <img
            src={whatsappLogo}
            alt='whatsapp'
            width={40}
            className='w-8'
          />
        </ExternalLink>
      </li>
    </ul>
  )
}

export default Social
