import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import MenuButton from './components/layout/MenuButton'
import Sidebar from './components/layout/sidebar/Sidebar'
import Footer from './components/layout/Footer'
import Home from './pages/Home'
import Servizi from './pages/Servizi'
import Contatti from './pages/Contatti'
import Azienda from './pages/Azienda'
import Faq from './pages/Faq'
import PrivateRoute from './pages/Dashboard/PrivateRoute'
import Dashboard from './pages/Dashboard/Dashboard'
import NotFound from './pages/NotFound'
import SelectLang from './components/layout/SelectLang'
import { Provider } from './context/BrmContext'

function App() {
  return (
    <>
      <Provider>
        <Router>
          <Sidebar />
          <SelectLang />

          <MenuButton />
          <div className='overflow-x-hidden page-content lg:ml-80 z-0 '>
            <Routes>
              <Route
                path='/brm-home-produttori-intimo-beachwear-swimwear-lingerie'
                index
                exact
                element={<Home />}
              />
              <Route path='/' index exact element={<Home />} />
              <Route
                path='/brm-servizi-produttori-intimo-costumi-abbigliamento-sportivo-lingerie'
                element={<Servizi />}
              />
              <Route
                path='/brm-services-producers-swimwear-beachwear-sportswear-lingerie'
                element={<Servizi />}
              />
              <Route path='/contatti' element={<Contatti />} />
              <Route path='/contacts' element={<Contatti />} />
              <Route path='/azienda' element={<Azienda />} />
              <Route path='/FAQ' element={<Faq />} />
              <Route path='/dashboard' element={<PrivateRoute />}>
                <Route path='/dashboard' element={<Dashboard />} />
              </Route>
              <Route path='/*' element={<NotFound />} />
            </Routes>
            <Footer />
          </div>
        </Router>
      </Provider>

      <ToastContainer autoClose={3000} />
    </>
  )
}

export default App
