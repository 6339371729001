import { useContext } from 'react'
import  BrmContext  from '../../context/BrmContext'

function MenuButton() {
  const { toggleSidebar } = useContext(BrmContext)

  return (
    <label
      tabIndex={0}
      className='btn btn-circle fixed top-3 left-8 z-50 cursor-pointer transition duration-500 lg:hidden'
      onClick={toggleSidebar}
    >
      <button>
        <svg
          xmlns='http://www.w3.org/2000/svg'
          className='h-8 w-8'
          viewBox='0 0 24 24'
          stroke='#EFEFEF'
          fill='rgb(0, 0, 0)'
        >
          <path
            stroke-linecap='round'
            stroke-linejoin='round'
            stroke-width='3px'
            d='M4 6h16M4 12h16M4 18h7'
          />
        </svg>
      </button>
    </label>
  )
}

export default MenuButton
