import { Bar } from 'react-chartjs-2'
import { ArcElement, Chart as ChartJS } from 'chart.js/auto'
import 'chartjs-adapter-date-fns'
import { useEffect } from 'react'



const BarChart = ({ chartData, colours }) => {
  //todayLine plugin block
  const todayLine = {
    id: 'todayLine',
    afterDatasetsDraw(chart, args, pluginOptions) {
      const {
        ctx,
        data,
        chartArea: { top, bottom, left, right },
        scales: { x, y },
      } = chart

      ctx.save()

      ctx.beginPath()
      ctx.lineWidth = 2
      ctx.strokeStyle = 'rgba(255, 26, 104, 1)'
      ctx.setLineDash([6, 6]) //linea tratteggiata 6px solid 6px white space
      ctx.moveTo(x.getPixelForValue(new Date()), top) //starting point (top y axis)
      ctx.lineTo(x.getPixelForValue(new Date()), bottom) // ending point (bottom y axis)
      ctx.stroke()

      ctx.setLineDash([])
    },
  }

  //assignedTasks plugin block
  const assignedTasks = {
    id: 'assignedTasks',
    afterDatasetsDraw(chart, args, pluginOptions) {
      const {
        ctx,
        data,
        chartArea: { top, bottom, left, right },
        scales: { x, y },
      } = chart

      ctx.save()
      ctx.font = 'bolder 12px sans-serif'
      ctx.fillStyle = 'black'
      ctx.textBaseline = 'middle' //mette nel mezzo il nome prima era posizionato leggermente più in alto
      data.datasets[0].data.forEach((objData, index) => {
        ctx.fillText(objData.name, 10, y.getPixelForValue(index))
      })
      ctx.fillText('Names', 10, top - 15)
      ctx.restore()
      //10px da sinistra
      // y.getPixelForValue ottiene la posizione del 0,1,2,3 ... index dell'asse y, (James, Luna, Genny.....)
    },
  }

  //status plugin block
  const status = {
    id: 'status',
    afterDatasetsDraw(chart, args, pluginOptions) {
      const {
        ctx,
        data,
        options,
        chartArea: { top, bottom, left, right },
        scales: { x, y },
      } = chart

      const icons = ['\uf00d', '\uf017', '\uf00c'] //0 1 2 index icons
    
      const angle = Math.PI / 180 
       //1 PI is half circle, so divided for 180 we get 1 degree, that multiplied for 360 will return one circle

      const paddingRight = options.layout.padding.right
      

      ctx.save()
      ctx.font = 'bolder 12px FontAwesome'
     
      ctx.textBaseline = 'middle'
      ctx.textAlign = 'center'
      data.datasets[0].data.forEach((objData, index) => {
        ctx.beginPath() //indipendent shape 
        ctx.fillStyle = colours[objData.status]
        // ctx.arc(x, y, radius, angleStart, angleEnd, false)
        ctx.arc(
          right + paddingRight / 2,
          y.getPixelForValue(index),
          12,
          0,
          angle * 360
        )
        ctx.closePath()
        ctx.fill()
        ctx.fillStyle = 'white'
        ctx.fillText(
          icons[objData.status],
          right + (paddingRight / 2),
          y.getPixelForValue(index)
        )
      })
      ctx.font = 'bolder 12px sans-serif'
      ctx.fillStyle = 'black'
      ctx.fillText('Status', right + (paddingRight/2), top - 15)
      ctx.restore() // non sovrascrive le proprietà sopra elencate con gli altri plugin

      // console.log(options.layout);
    },
  }

  return (
    <div className='p-5 w-full'>
      <h2 style={{ textAlign: 'center' }}>Bar Chart</h2>
      <Bar
        data={chartData}
        options={{
          layout: {
            padding: {
              left: 100, //il chart avrà 100px di padding left
              right: 60,
            },
          },
          indexAxis: 'y',
          scales: {
            x: {
              position: 'top', //le date su e non giu
              type: 'time',
              time: {
                displayFormats: {
                  day: 'd'
                }
              },
              min: '2023-01-01',
              max: '2023-01-31',
            },
          },
          plugins: {
            // title: {
            //   display: true,
            //   text: 'Users Gained between 2016-2020',
            // },
            legend: {
              display: false,
            },
            tooltip: {
              callbacks: {
                title: (ctx) => {
                  console.log(ctx[0])
                  const startDate = new Date(ctx[0].raw.x[0])
                  const endDate = new Date(ctx[0].raw.x[1])
                  const formattedStartDate = startDate.toLocaleString([], {
                    year: 'numeric',
                    month: 'short',
                    day: 'numeric',
                    hour12: false
                  })
                  const formattedEndDate = endDate.toLocaleString([], {
                    year: 'numeric',
                    month: 'short',
                    day: 'numeric',
                    hour12: false
                  })
                  return [ctx[0].raw.name, `Task Deadline: ${formattedStartDate} - ${formattedEndDate}`]
                  console.log(startDate);
                }
              }
            }
          },
        }}
        plugins={[todayLine, assignedTasks, status]}
      />
    </div>
  )
}

export default BarChart
