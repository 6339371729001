import { useState } from 'react'
import { addDoc, collection, serverTimestamp } from 'firebase/firestore'
import { useNavigate } from 'react-router-dom'
import { db } from '../../../firebase.config'
import { toast } from 'react-toastify'
import { useRef } from 'react'
import emailjs from '@emailjs/browser'
import { useContext } from 'react'
import BrmContext from '../../../context/BrmContext'

const initialState = {
  name: '',
  email: '',
  tel: '',
  type: '',
  service: '',
  place: '',
  country: '',
  knownBy: '',
  message: '',
}

const Form = () => {
  const [formData, setFormData] = useState(initialState)
  const form = useRef()
  const navigate = useNavigate()

  const { t } = useContext(BrmContext)

  const { name, email, tel, type, service, place, country, knownBy, message } =
    formData

  const onMutate = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }))
  }

  const sendForm = async () => {
    console.log(formData)

    if (
      name === '' ||
      email === '' ||
      type === '' ||
      service === '' ||
      place === '' ||
      knownBy === ''
    ) {
      toast.error('Per favore compilare tutti i campi')
      return
    }

    // Add timestamp to formData
    formData.timestamp = serverTimestamp()
    try {
      await addDoc(collection(db, 'richieste'), formData)
      toast.success('Form inviato correttamente!')
      navigate('/')
    } catch (error) {
      toast.error('Si è verificato un errore, riprova')
    }
  }

  //EMAIL JS
  const sendEmail = async () => {
    try {
      const result = await emailjs.sendForm(
        'service_mdg2d6m',
        'template_tb8rtwy',
        form.current,
        'MG_JEJDhQmX38-pPc'
      )
      console.log(result)
    } catch (error) {
      console.log(error)
    }
  }

  const onSubmit = (e) => {
    e.preventDefault()
    sendForm()
    sendEmail()
  }

  return (
    <>
      <form ref={form} onSubmit={onSubmit}>
        <div className='mb-3 mt-3'>
          <input
            type='text'
            className='input input-bordered w-full text-l lg:text-xl'
            id='name'
            name='name'
            value={name}
            placeholder={t('form_name')}
            onChange={onMutate}
            required
          />
        </div>
        <div className='mb-3'>
          <input
            type='text'
            className='input input-bordered w-full text-l lg:text-xl'
            id='email'
            name='email'
            value={email}
            placeholder='Email'
            onChange={onMutate}
            required
          />
        </div>
        <div className='mb-3'>
          <input
            type='text'
            className='input input-bordered w-full text-l lg:text-xl'
            id='tel'
            name='tel'
            value={tel}
            placeholder={t('form_phone')}
            onChange={onMutate}
            required
          />
        </div>
        <div className='form-control w-full max-w-3/4 mb-3'>
          <label className='label'>
            <span className='label-text text-l lg:text-xl font-semibold'>
              {t('form_tipo')}
            </span>
          </label>
          <select
            name='type'
            className='select select-bordered text-l lg:text-xl'
            onChange={onMutate}
          >
            <option disabled selected>
              {' '}
              -{' '}
            </option>
            <option value='Intimo'>{t('form_risposte_tipo1')}</option>
            <option value='Lingerie'>{t('form_risposte_tipo2')}</option>
            <option value='Costumi da bagno'>{t('form_risposte_tipo3')}</option>
            <option value='Sportswear'>{t('form_risposte_tipo4')}</option>
            <option>{t('form_risposte_altro')}</option>
          </select>
        </div>
        <div className='form-control w-full max-w-3/4 mb-3'>
          <label className='label'>
            <span className='label-text text-m lg:text-xl font-semibold'>
              {t('form_servizio')}
            </span>
          </label>
          <select
            name='service'
            className='select select-bordered text-l lg:text-xl'
            onChange={onMutate}
          >
            <option disabled selected>
              {' '}
              -{' '}
            </option>
            <option value='Campionatura / Cartamodelli'>
              {t('form_risposte_servizio1')}
            </option>
            <option value='Produzione'>{t('form_risposte_servizio2')}</option>
            <option value='Campionatura e Produzione'>
              {t('form_risposte_servizio3')}
            </option>
            <option>{t('form_risposte_altro')}</option>
          </select>
        </div>
        <div className='form-control w-full max-w-3/4 mb-3'>
          <label className='label'>
            <span className='label-text text-m lg:text-xl font-semibold'>
              {t('form_da_dove')}
            </span>
          </label>
          <select
            name='place'
            className='select select-bordered text-l lg:text-xl'
            onChange={onMutate}
          >
            <option disabled selected>
              {' '}
              -{' '}
            </option>
            <option value='Italia'>Italia</option>
            <option value='Europa'>Europa</option>
            <option value='America'>America</option>
            <option value='Asia'>Asia</option>
            <option value='Africa'>Africa</option>
          </select>
        </div>
        {place !== 'Italia' && place !== '' && (
          <div className='form-control w-full max-w-3/4 mb-3'>
            <label className='label'>
              <span className='label-text text-m lg:text-xl font-semibold'>
                {t('form_stato')}
              </span>
            </label>
            <input
              type='text'
              className='input input-bordered w-full text-l lg:text-xl'
              id='country'
              name='country'
              value={country}
              placeholder={t('form_stato')}
              onChange={onMutate}
              required
            />
          </div>
        )}
        <div className='form-control w-full max-w-3/4 mb-3'>
          <label className='label'>
            <span className='label-text text-m lg:text-xl font-semibold'>
              {t('form_come_conosciuti')}
            </span>
          </label>
          <select
            name='knownBy'
            className='select select-bordered text-l lg:text-xl'
            onChange={onMutate}
          >
            <option disabled selected>
              {' '}
              -{' '}
            </option>
            <option value='Website'>Website</option>
            <option value='Social'>Social</option>
            <option value='B2B Marketplace'>B2B Marketplace</option>
            <option>{t('form_risposte_altro')}</option>
          </select>
        </div>
        <div className='form-control w-full max-w-3/4 mb-3'>
          <label className='label'>
            <span className='label-text text-m lg:text-xl font-semibold'>
              {t('form_messaggio')}
            </span>
          </label>
          <textarea
            className='textarea textarea-bordered text-l lg:text-xl'
            rows={4}
            placeholder={t('form_messaggio_placeholder')}
            name='message'
            value={message}
            onChange={onMutate}
          ></textarea>
        </div>
        {/* <button
          data-tooltip-target='tooltip-default'
          type='button'
          className='underline text-gray-600 text-xs'
        >
          * {t('trattamento_dati')}
        </button> */}
        {/* <div
          id='tooltip-default'
          role='tooltip'
          className='inline-block absolute invisible z-10 py-2 px-3 text-sm font-medium text-white bg-gray-900 rounded-lg'
        >
          {t('trattamento_dati_contenuto')}
          <div className='tooltip-arrow' data-popper-arrow></div>
        </div> */}
        <div className='tooltip min-w-2xl ' data-tip={t("trattamento_dati_contenuto")}>
          <span>*</span> <span className='underline text-gray-600 text-xs'>{t("trattamento_dati")} </span>
        </div>
        <button
          type='submit'
          className='btn w-full mt-3 mx-auto hover:bg-white hover:text-black'
        >
          Submit
        </button>
      </form>
    </>
  )
}

export default Form
