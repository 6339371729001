import Login from "../Dashboard/Login"
import { Outlet } from 'react-router-dom'
//Outlet allow us to render child routes or child elements such as Children
import useAuthStatus from '../../hooks/useAuthStatus'

const PrivateRoute = () => {
  const loggedIn = useAuthStatus()

  return loggedIn? <Outlet /> : <Login />
}

export default PrivateRoute
