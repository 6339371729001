import { useContext } from 'react'
import BrmContext from '../../../context/BrmContext'
import Social from './Social'
import logo from '../../assets/logo/logo.png'
import SidebarList from './SidebarList'

function Sidebar() {
  const { sidebarOpen } = useContext(BrmContext)

  return (
    <div className='sidebar'>
      <nav
        className={
          sidebarOpen === false
            ? 'navbar flex flex-col justify-between fixed top-0 left-0 w-full h-full border-r-2 border-transparent -translate-x-full z-50 transition duration-300 ease-in-out lg:translate-x-0 md:w-80 shadow-4xl bg-gray-100'
            : 'navbar flex flex-col justify-between fixed top-0 left-0 w-full  h-full border-r-2 border-transparent z-50 transition duration-300 ease-in-out lg:translate-x-0 md:w-80 shadow-4xl bg-gray-100'
        }
      >
        <div className='logo text-center py-6 mx-auto'>
          <img src={logo} alt='logo' name='BRM' />
        </div>

        <SidebarList />

        <Social />
      </nav>
    </div>
  )
}

export default Sidebar
