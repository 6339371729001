import Hero from '../components/layout/Hero'
import Servizi from './Servizi'
import { useEffect } from 'react'
import Faq from './Faq'
import Contatti from './Contatti'
import Loader from '../components/Loader'
import { Helmet } from 'react-helmet-async'
import { useState } from 'react'

function Home() {
  const [isLoading, setIsLoading] = useState(true)
  
  useEffect(() => {
    window.scrollTo(0, 0)
    setInterval(() => {
      setIsLoading(false)
    }, 1000)
  }, [])

  return isLoading ? (
    <Loader />
  ) : (
    <>
      <Helmet>
        <title>
          BRM Confezioni Produttori di Intimo e Costumi da bagno | Home
        </title>
        <meta
          name='description'
          content='BRM Confezioni Produttori di intimo, beachwear e sportswear di alta qualità da più di 30 anni per i più grandi brand.'
        />
        <link
          rel='canonical'
          href='brm-home-produttori-intimo-beachwear-swimwear-lingerie'
        />
      </Helmet>

      <Hero />
      {/* <section className='block md:hidden'>
        <Servizi />
      </section> */}
    </>
  )
}

export default Home
