import TitlePage from '../components/layout/TitlePage'
import FaqItem from '../components/layout/FaqItem'
import { useContext, useEffect, useState } from 'react'
import BrmContext from '../context/BrmContext'
import { Helmet } from 'react-helmet-async'
import Loader from '../components/Loader'

function Faq() {
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    window.scrollTo(0, 0)
    setInterval(() => {
      setIsLoading(false)
    }, 1000)
  }, [])
  const { t } = useContext(BrmContext)

  const faqList = [
    {
      question: t('faq_moq_titolo'),
      answer: t('faq_moq_testo'),
      animation: 'animate__animated animate__pulse',
    },
    {
      question: t('faq_tempoRealizzazioneCampioni_titolo'),
      answer: t('faq_tempoRealizzazioneCampioni_testo'),
      animation: 'animate__animated animate__pulse',
    },
    {
      question: t('faq_serviziOfferti_titolo'),
      answer: t('faq_serviziOfferti_testo_0'),
      listArr: [
        t('faq_serviziOfferti_testo_1'),
        t('faq_serviziOfferti_testo_2'),
        t('faq_serviziOfferti_testo_3'),
        t('faq_serviziOfferti_testo_4'),
        t('faq_serviziOfferti_testo_5'),
        t('faq_serviziOfferti_testo_6'),
      ],
      animation: 'animate__animated animate__pulse',
    },
    {
      question: t('faq_RivoltoServizio_titolo'),
      answer: t('faq_RivoltoServizio_testo'),
      animation: 'animate__animated animate__pulse',
    },
    {
      question: t('faq_ServizioPrivati_titolo'),
      answer: t('faq_ServizioPrivati_testo'),
      animation: 'animate__animated animate__pulse',
    },
    {
      question: t('faq_materiali_titolo'),
      answer: t('faq_materiali_testo'),
      animation: 'animate__animated animate__pulse',
    },
    {
      question: t('faq_produzione_titolo'),
      answer: t('faq_produzione_testo'),
      animation: 'animate__animated animate__pulse',
    },
    {
      question: t('faq_cosaFornire_titolo'),
      answer: t('faq_cosaFornire_testo'),
      animation: 'animate__animated animate__pulse',
    },
  ]

  return isLoading ? (
    <Loader />
  ) : (
    <>
      <Helmet>
        <title>
          BRM Confezioni Produttori di Intimo e Costumi da bagno | FAQ
        </title>
        <meta
          name='description'
          content='Consulta le domande e risposte più frequenti.'
        />
        <link rel='canonical' href='/FAQ' />
      </Helmet>

      <section className='min-h-screen w-full p-5'>
        <TitlePage title={'FAQ'} />
        <div className='my-12'>
          {faqList.map(({ question, answer, listArr, animation }, index) => (
            <FaqItem
              key={index}
              question={question}
              answer={answer}
              listArr={listArr}
              animation={animation}
            />
          ))}
        </div>
      </section>
    </>
  )
}

export default Faq
