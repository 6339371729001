import ServiceLayout from '../components/layout/services/ServiceLayout'
import campImg from '../components/assets/bg/service-1.jpeg'
import prodImg from '../components/assets/bg/service-2.jpeg'
import preformImg from '../components/assets/bg/preformImg.jpeg'
import ricercaMatImg from '../components/assets/bg/ricercaMat.jpeg'
import stiroImg from '../components/assets/bg/stiroImg.jpeg'
import TitlePage from '../components/layout/TitlePage'
import { useContext } from 'react'
import BrmContext from '../context/BrmContext'
import { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import Loader from '../components/Loader'

function Servizi() {
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    window.scrollTo(0, 0)
    setInterval(() => {
      setIsLoading(false)
    }, 1000)
  }, [])
  const { t } = useContext(BrmContext)

  const services = [
    {
      title: `${t('servizi_campionatura_titolo')}`,
      text: `${t('servizi_campionatura_testo')}`,
      photo: campImg,
      position: 'left',
      animation: 'fade-up-right',
    },
    {
      title: `${t('servizi_produzione_titolo')}`,
      text: `${t('servizi_produzione_testo')}`,
      photo: prodImg,
      animation: 'fade-up-left',
    },
    {
      title: `${t('servizi_ricercaMat_titolo')}`,
      text: `${t('servizi_ricercaMat_testo')}`,
      photo: ricercaMatImg,
      position: 'left',
      animation: 'fade-up-right',
    },
    {
      title: t('servizi_preformazione_titolo'),
      text: t('servizi_preformazione_testo'),
      photo: preformImg,
      animation: 'fade-up-left',
    },
    {
      title: `${t('servizi_confezione_titolo')}`,
      text: `${t('servizi_confezione_text')}`,
      photo: stiroImg,
      position: 'left',
      animation: 'fade-up-right',
    },
  ]

  return isLoading ? (
    <Loader />
  ) : (
    <>
      <Helmet>
        <title>
          BRM Confezioni Produttori di Intimo e Costumi da bagno | Servizi
        </title>
        <meta
          name='description'
          content='Sfoglia i servizi offerti da BRM Confezioni.'
        />
        <link
          rel='canonical'
          href='/brm-servizi-produttori-intimo-costumi-abbigliamento-sportivo-lingerie'
        />
      </Helmet>

      <TitlePage title={t('sidebar_servizi')} />
      <section className='min-h-screen w-full p-5 '>
        <div className='z-0 mt-12'>
          {services.map(
            ({ title, text, photo, position, animation }, index) => (
              <ServiceLayout
                key={index}
                title={title}
                text={text}
                photo={photo}
                position={position}
                animation={animation}
              />
            )
          )}
        </div>
      </section>
    </>
  )
}

export default Servizi
