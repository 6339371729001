import { MapContainer, Marker, Popup, LayersControl, TileLayer } from 'react-leaflet'


const Map = () => {
  const position = [44.9911541, 10.8442045]

  return (
      <MapContainer
        center={position}
        zoom={13}
        scrollWheelZoom={false}
        style={{width: '450px', height: '400px'}}
        className='map-container'
      >
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
        />
        <Marker position={position}>
          <Popup>
            BRM CONFEZIONI <br />
            Via Sandro Pertini 2/A Pegognaga 46020 (MN)
          </Popup>
        </Marker>
      </MapContainer>
  
  )
}

export default Map
