import loader from './assets/loader/loader.gif'
import logoLoader from './assets/logo/logoLoader.jpg'

import React from 'react'

const Loader = () => {
  return (
    <div className='loader h-screen flex flex-col justify-center items-center'>
      <img src={loader} alt='loader' />
      <img src={logoLoader} alt='logo' className='w-[12rem] h-auto'/>
    </div>
  )
}

export default Loader
