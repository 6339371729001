function ContactItem({ name, link, contact, img, animation }) {
  return (
    <div
      className={`contactItem ${animation} flex flex-col  lg:flex-row align-center my-2`}
    >
      <div className=' self-center text-center'>
        <a
          href={link}
          target='_blank'
          rel='noreferrer'
          className='flex flex-col justify-center align-center'
        >
          <img
            src={img}
            alt='message'
            className='hover:scale-110 mr-2 transition w-14 md:w-22 self-center'
          />

          {Array.isArray(contact) ? (
            <>
              <p className='text-s lg:text-l self-center'>{contact[0]}</p>
              <p className='text-s lg:text-l self-center'>{contact[1]}</p>{' '}
            </>
          ) : (
            <p className='text-s lg:text-l self-center'>{contact}</p>
          )}
        </a>
      </div>
    </div>
  )
}

export default ContactItem
