import Aos from 'aos'
import 'aos/dist/aos.css'
import { useEffect } from 'react'

function ServiceLayout({ title, text, photo, position, animation }) {
  
  useEffect(() => {
    Aos.init({ duration: 500 })
  }, [])

  return (
    <div
      data-aos={animation}
      className='relative w-full h-80 mb-12 md:h-96 md:mb-16 mt-6'
    >
      <div className='service-text collapse collapse-arrow  w-5/6 lg:w-3/5 bg-white absolute -top-12 z-10 left-1/2 -translate-x-1/2 p-2 lg:-top-9 shadow-3xl '>
        <input type='checkbox' className='peer' />
        <div className='collapse-title text-center text-3xl xl:text-4xl font-semibold mb-3'>
          {title} 
        </div>
        <div className='collapse-content text-xl xl:text-2xl leading-6'>
          {text}
        </div>
      </div>
      <div
        className={
          position === 'left'
            ? 'service-img absolute w-full sm:left-0 max-w-xl lg:w-3/4 lg:h-5/6'
            : 'service-img absolute w-full sm:right-0 max-w-xl lg:w-3/4 lg:h-5/6'
        }
      >
        <img
          src={photo}
          alt='BRM Confezioni'
          name='BRM Confezioni'
          className='shadow-4xl w-full lg:w-100 md:h-96 rounded-lg h-72'
        />
      </div>
    </div>
  )
}

export default ServiceLayout
