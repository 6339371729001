import { createContext, useState } from 'react'
import { useTranslation } from 'react-i18next'

const BrmContext = createContext()

export const Provider = ({ children }) => {
  const [sidebarOpen, setSidebarOpen] = useState(false)

  const toggleSidebar = () => {
    setSidebarOpen((prevState) => !prevState)
  }

  const { t } = useTranslation()

  return (
    <BrmContext.Provider value={{ toggleSidebar, sidebarOpen, t }}>
      {children}
    </BrmContext.Provider>
  )
}

export default BrmContext
