import { FaHome } from 'react-icons/fa'
import { Link } from 'react-router-dom'
import logo404 from '../components/assets/logo/logo404.jpg'

const NotFound = () => {
  return (
    <div className='hero h-screen'>
      <div className='text-center hero-content'>
        <div className='max-w-lg'>
          <h1 className='text-6xl font-bold mb-8'>Oops!</h1>
          <img src={logo404} alt="logo" className='mt-[5rem]' />
          <p className='text-5xl mb-8 mt-[5rem]'>Page not found!</p>
          <Link to='/' className='btn btn-primary btn-lg'>
            <FaHome className='mr-2' />
            Back to Home
          </Link>
        </div>
      </div>
    </div>
  )
}

export default NotFound
