import { useContext } from "react"
import { NavLink } from "react-router-dom"
import BrmContext from "../../../context/BrmContext"

function SidebarListItem({img, name, url}) {
  const { toggleSidebar} = useContext(BrmContext)

  return (
    <li className='flex w-40 justify-start md:hover:-translate-x-3 transition ease-in-out mb-[30px]'>
      <span className='w-12 text-xl md:mr-3'>
        <img src={img} alt='name' className='w-9 w-12' />
      </span>
      <NavLink
        to={url}
        className={({ isActive }) =>
          isActive
            ? 'underline underline-offset-4 font-semibold text-xl self-center'
            : 'font-semibold text-xl  self-center'
        }
        onClick={toggleSidebar}
      >
        {name}
      </NavLink>
    </li>
  )
}

export default SidebarListItem
