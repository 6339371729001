import { useContext, useEffect, useState } from 'react'
import TitlePage from '../components/layout/TitlePage'
import { Helmet } from 'react-helmet-async'
import AziendaItem from '../components/layout/azienda/AziendaItem'
import sedeImg from '../components/assets/bg/sede.jpeg'
import produttoriImg from '../components/assets/bg/produttoriImg.jpg'
import AnimatedLetters from '../components/AnimatedLetters/AnimatedLetters'
import BrmContext from '../context/BrmContext'
import Loader from '../components/Loader'

const Azienda = () => {
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    window.scrollTo(0, 0)
    setInterval(() => {
      setIsLoading(false)
    }, 1000)
  }, [])

  const { t } = useContext(BrmContext)

  const subtitle = t('azienda_sottotitolo')

  const articles = [
    {
      title: t('azienda_sede_titolo'),
      text: t('azienda_sede_testo'),
      img: sedeImg,
    },
    {
      title: t('azienda_produttori_titolo'),
      text1: t('azienda_produttori_testo1'),
      text2: t('azienda_produttori_testo2'),
      text3: t('azienda_produttori_testo3'),
      position: 'right',
      img: produttoriImg,
    },
  ]

  return isLoading ? (
    <Loader />
  ) : (
    <>
      {' '}
      <Helmet>
        <title>BRM Confezioni | Azienda</title>
        <meta
          name='description'
          content="Descrizione dell'azienda, caratteristiche principali che ci distinguono."
        />
        <link rel='canonical' href='/azienda' />
      </Helmet>
      <TitlePage title={t('azienda_titolo')} />
      <section className='w-full p-8'>
        <h3 className='text-2xl md:text-3xl font-semibold w-full mx-auto mb-10 leading-10 text-center'>
          <AnimatedLetters
            letterClass='subtitle-animate'
            strArray={subtitle.split('')}
            idx={1}
          />
        </h3>
        {articles.map(({ title, text, text1, text2, text3, position, img }) => (
          <AziendaItem
            title={title}
            text={text}
            text1={text1}
            text2={text2}
            text3={text3}
            position={position}
            img={img}
          />
        ))}
      </section>
    </>
  )
}

export default Azienda
