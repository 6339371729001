import homeLogo from '../../assets/icon/home.svg'
import servicesLogo from '../../assets/icon/services.svg'
import contactLogo from '../../assets/icon/contact.svg'
import aziendaLogo from '../../assets/icon/azienda.png'
import questionMark from '../../assets/icon/questionMark.png'
import SidebarListItem from './SidebarListItem'
import { useContext } from 'react'
import BrmContext from '../../../context/BrmContext'

function SidebarList({ toggleSidebar }) {
  const { t } = useContext(BrmContext)

  const linksNav = [
    {
      img: homeLogo,
      name: 'Home',
      url: 'brm-home-produttori-intimo-beachwear-swimwear-lingerie',
    },
    {
      img: aziendaLogo,
      name: t("azienda_titolo"),
      url: 'azienda',
    },
    {
      img: servicesLogo,
      name: `${t('sidebar_servizi')}`,
      url: 'brm-servizi-produttori-intimo-costumi-abbigliamento-sportivo-lingerie',
    },
    {
      img: contactLogo,
      name: `${t('sidebar_contatti')}`,
      url: 'contatti',
    },
    {
      img: questionMark,
      name: 'FAQ',
      url: 'FAQ',
    },
  ]
  return (
    <ul className='flex flex-col'>
      {linksNav.map(({img, name, url}, index) => (
        <SidebarListItem
          key={index}
          img={img}
          name={name}
          url={url}
        />
      ))}
    </ul>
  )
}

export default SidebarList
