import { Link } from 'react-router-dom'
import BrmContext from '../../context/BrmContext'
import { useContext, useState } from 'react'
import video from '../assets/video/compresso2.mp4'
import AnimatedLetters from '../AnimatedLetters/AnimatedLetters'

function Hero() {
  const { t } = useContext(BrmContext)

  return (
    <>
      <header className='relative flex items-center justify-center h-screen mb-12 overflow-hidden'>
        <div className='absolute top-0 right-0 left-0 bottom-0 w-auto min-w-full min-h-full max-w-none bg-black z-10 opacity-40'></div>
        <div className='relative z-30 p-5 text-white text-center max-w-xl lg:max-w-xl'>
          <h1 className='mb-5 text-4xl md:text-6xl lg:text-7xl font-bold text-animate'>
            <span className='block mb-3 tracking-widest'>
              <AnimatedLetters
                letterClass='text-animate'
                strArray={'BRM'.split('')}
                idx={1}
              />{' '}
            </span>{' '}
            <AnimatedLetters
              letterClass='text-animate'
              strArray={'CONFEZIONI'.split('')}
              idx={4}
            />
          </h1>
          <p className='mb-5 text-xl lg:text-2xl lg:leading-9 font-semibold px-5 animate__animated animate__fadeIn animate__delay-3s'>
            {t('home')}
          </p>
          <Link
            to='/contatti'
            className='animate__animated animate__fadeInDown animate__delay-3s'
          >
            <button className='btn btn-outline lg:btn-lg text-white md:hover:scale-110 transition duration-300 '>
              {t('home_contattaci')}
            </button>
          </Link>
        </div>
        <video
          autoPlay
          playsInline
          loop
          muted
          className='absolute z-5 w-auto min-w-full min-h-full max-w-none'
        >
          <source src={video} type='video/mp4' />
          Your browser does not support the video tag.
        </video>
      </header>
    </>
  )
}


  /* <div className='hero-overlay bg-opacity-50 w-5/6 h-5/6 p-3 animate__animated animate__fadeIn animate__delay-1s'></div>
        <div className='hero-content text-center text-neutral-content animate__animated animate__fadeIn animate__delay-1s'>
          <div className='max-w-xs lg:max-w-xl'>
            <h1 className='mb-5 text-4xl lg:text-7xl font-bold'>
              <span className='block mb-3 tracking-widest'>BRM </span>{' '}
              CONFEZIONI
            </h1>
            <p className='mb-5 text-xl lg:text-2xl lg:leading-9 font-semibold px-5'>
              {t('home')}
            </p>
            <Link to='/contatti'>
              <button className='btn btn-outline lg:btn-lg text-white md:hover:scale-110 transition duration-300'>
                {t('home_contattaci')}
              </button>
            </Link>
          </div>
        </div> */


export default Hero
