import React from 'react'
import Chart from './Chart/Chart'

const Dashboard = () => {
  return (
    <div>
      <Chart />
    </div>
  )
}

export default Dashboard
